
            @import 'src/styles/mixins.scss';
        
.shadowStyle {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}
.buttonStyle {
    background: linear-gradient(180deg, #ffe49a 0%, #eac974 100%);
    border-radius: 54px;
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: translateY(-8px);
        transition: all 0.3s ease-in-out;
    }
}
